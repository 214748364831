import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { AppLayout } from 'modules/app';
import { SSROpeningPreview } from './components/SSROpeningPreview';
import { SSRError } from 'modules/app';

interface Props extends RouteComponentProps {
  opening?: PublicOpening;
  error?: ApiErrorData;
}

export const SSRPublicOpening: React.FC<Props> = ({ opening, error }) => {
  return (
    <AppLayout isPublic>
      {error && <SSRError error={error} />}
      {opening && <SSROpeningPreview opening={opening} />}
    </AppLayout>
  );
};
