import React from 'react';
import { httpClient, makeBaseImagePath } from 'modules/app';
import Seo from 'modules/app/components/Seo';
import * as https from 'https';
import fs from 'fs';
import path from 'path';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';
import { SSRPublicOpening } from 'modules/publicOpening/SSRPublicOpening';

interface GetSingleOpening {
  opening?: PublicOpening;
  error?: ApiErrorData;
}

const getSingleOpeningFn = async (
  companyId: string | undefined,
  openingId: string,
): Promise<GetSingleOpening> => {
  const agent = new https.Agent({
    ca: fs.readFileSync(path.resolve(`${__dirname}/../../../static/ca.crt`)),
  });

  const res = await httpClient<PublicOpening>({
    url: `${process.env.GATSBY_API_BASE_URL}/api/v1/company/${companyId}/profile/openings/${openingId}`,
    method: 'GET',
    httpsAgent: process.env.GATSBY_ENV === 'development' ? agent : null,
  });

  return {
    opening: res.data,
    error: res.error,
  };
};

function resolveTitle(opening: PublicOpening | undefined): string {
  if (!opening) {
    return 'Roango - Connecting Tech Talents with Great Companies';
  }

  if (opening.metaTitle) {
    return opening.metaTitle;
  }

  return opening.title;
}

interface Props {
  serverData: {
    opening?: PublicOpening;
    error?: ApiErrorData;
  };
}

const OpeningSlug: React.FC<Props> = ({ serverData }) => {
  const { siteUrl } = useSiteMetadata();
  const image = serverData.opening?.metaImage;

  const imagePath = image
    ? makeBaseImagePath(image)
    : `${siteUrl}/roango-share-image.png`;

  return (
    <>
      <Seo
        title={resolveTitle(serverData.opening)}
        description={
          (serverData.opening && serverData.opening.metaDescription) ||
          undefined
        }
        image={imagePath}
      />

      <SSRPublicOpening opening={serverData.opening} error={serverData.error} />
    </>
  );
};

export async function getServerData(context: {
  params: Record<string, string>;
}) {
  const companyHandle = context.params.companyHandle;
  const openingSlug = context.params.openingSlug;

  const { opening, error } = await getSingleOpeningFn(
    companyHandle,
    openingSlug,
  );

  if (error) {
    return {
      status: error.status,
      props: {
        error: error,
        opening: opening,
      },
      headers: {
        'Cache-Control': 'public, max-age=0, must-revalidate',
      },
    };
  }

  return {
    props: {
      opening: opening,
    },
    headers: {
      'Cache-Control': 'public, max-age=0, must-revalidate',
    },
  };
}

export default OpeningSlug;
